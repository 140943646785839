import { useEffect } from "react"

const FB = () => {
  useEffect(() => {
    window.location.replace(
      "https://www.facebook.com/Ko%C5%9Bci%C3%B3%C5%82-Chrze%C5%9Bcijan-Baptyst%C3%B3w-OAZA-w-Wi%C5%9Ble-223677011113107"
    )
  })

  return null
}

export default FB
